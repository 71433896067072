<script>
export default {
  name: 'ExternalItemLink',
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    logsdata () {
    },
    redirectToJob (id) {
      const url = window.location.origin + '/c/' + this.selectedWorkspace.subdomain + '/hire/jobs/' + id
      window.location.href = (url)
      return url
    }
  }
}
</script>
<template>
  <div class="external-item-link--container">
    <div class="external-item-link--left">
      <p class="external-item-link--title">{{ item.name }}</p>
      <div class="external-item-link--bottom-info">
        <div class="external-item-link--bottom-item">
          <span class="fake-icon">#</span>
          <span class="external-item-link--bottom-text">{{ item.id }}</span>
        </div>
        <div class="external-item-link--bottom-item">
          <v-icon color="#212121" size="14px">mdi-calendar</v-icon>
          <span class="external-item-link--bottom-text">{{ item.date }}</span>
        </div>
        <div class="external-item-link--bottom-item">
          <v-icon color="#212121" size="14px">mdi-map-marker</v-icon>
          <span class="external-item-link--bottom-text">{{ item.city }}</span>
        </div>
      </div>
    </div>
    <div class="external-item-link--right">
      <v-icon class="clickable" @click=redirectToJob(item.click.id)>mdi-open-in-new</v-icon>
    </div>
</div>
</template>
<style lang="scss">
.external-item-link--container {
  padding: 16px 24px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .external-item-link--left {
    display: flex;
    flex-direction: column;

    .external-item-link--title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #212121;
      margin-bottom: 0;
    }

    .external-item-link--bottom-info {
      display: flex;

      .external-item-link--bottom-item {
        margin-right: 16px;

        .fake-icon {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          color: #212121;
        }

        .external-item-link--bottom-text {
          margin-left: 4px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          color: #767676;
        }
      }
    }
  }

  .external-item-link--right {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 640px) {
  .external-item-link--container {
    display: flex;

    .external-item-link--left {
      display: flex;
      flex-direction: column;

      .external-item-link--bottom-info {
        display: flex;
        flex-direction: column;
        padding: 10px 0px;
      }
    }
  }

}</style>
